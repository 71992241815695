import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { H1, P } from '../../components/Text';

import {
  FONT_BOLD,
  FONT_REGULAR,
  screenLessThan,
  SCREEN_PAD,
} from '../../shared/styles';

const HeroSection = () => {
  const { t } = useTranslation();

  return (
    <HeroContainer>
      <MainTitle>{t('home.hero-title')}</MainTitle>
      <MainSubHeading className="subtitle">
        {t('home.hero-subtitle')}
      </MainSubHeading>
    </HeroContainer>
  );
};
export default HeroSection;

const MainTitle = styled(H1)`
  font-size: 72px;
  ${FONT_BOLD}
  margin: auto;
  margin-bottom: 1.8rem;
  width: 70%;
  ${screenLessThan('1500px')} {
    font-size: 4.2rem;
  }
  ${screenLessThan('1000px')} {
    font-size: 2.7rem;
  }
  ${screenLessThan('770px')} {
    font-size: 38px;
    margin: auto;
    margin-bottom: 1rem;
    width: 100%;
  }
`;
const MainSubHeading = styled(P)`
  font-size: 16px;
  line-height: 24px;
  margin: auto;
  margin-bottom: 2rem;
  width: 50%;
  ${screenLessThan('770px')} {
    font-size: 17px;
    margin: auto;
    margin-bottom: 1.2rem;
    width: 100%;
  }
`;

const HeroContainer = styled.section`
  display: block;
  padding-top: 2%;
  text-align: center;
  ${SCREEN_PAD}
  .subtitle {
    font-size: 16px;
    margin: auto;
    margin-top: 1rem;
    text-align: center;
    width: 50%;
    ${FONT_REGULAR}
    ${screenLessThan('770px')} {
      font-size: 17px;
      width: 70%;
    }
  }
`;
