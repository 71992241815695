import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { P } from '../../components/Text';
import WisitaVideo from '../../components/WisitaVideo';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { PRIMARY_COLOR, screenLessThan, SCREEN_PAD } from '../../shared/styles';
import close from '../../../static/svgs/home/close.svg';
import play from '../../../static/svgs/home/play.svg';

const HomeVideo = () => {
  const screenSize = useWindowDimensions().width;
  const ref = useRef(null);
  const { t } = useTranslation();
  const [playVideo, setPlayVideo] = useState(false);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setPlayVideo(false);
      }
    },
    [ref, setPlayVideo]
  );

  const preventScrolling = useCallback(
    (event) => {
      if (playVideo) {
        event.preventDefault();
      }
    },
    [playVideo]
  );

  useEffect(() => {
    document.body.addEventListener('wheel', preventScrolling, {
      passive: false,
    });
    document.body.addEventListener('touchmove', preventScrolling, {
      passive: false,
    });

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.body.removeEventListener('wheel', preventScrolling);
      document.body.removeEventListener('touchmove', preventScrolling);

      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, preventScrolling, screenSize]);

  return (
    <HomeVideoWrapper>
      <BizVideoText onClick={() => setPlayVideo(true)}>
        <PlayButton src={play} />
        {t('home.wisita-video.title')}
      </BizVideoText>
      <VideoPlayerWrap ref={ref} playVideo={playVideo}>
        <CloseButton onClick={() => setPlayVideo(false)} src={close} />
        {playVideo && <WisitaVideo />}
      </VideoPlayerWrap>
    </HomeVideoWrapper>
  );
};

const PlayButton = styled.img`
  display: inline-block;
  margin-right: 1rem;
`;

const CloseButton = styled.img`
  cursor: pointer;
  left: 1%;
  position: absolute;
  top: 1%;
  width: 30px;
  z-index: 10;
`;

const BizVideoText = styled(P)`
  color: ${PRIMARY_COLOR};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 50%;
  ${screenLessThan('1000px')} {
    width: 90%;
  }
`;

const HomeVideoWrapper = styled.div`
  display: block;
  justify-content: center;
  position: relative;
  ${SCREEN_PAD}
  ${screenLessThan('1000px')} {
    padding-bottom: 3rem;
  }
`;
const VideoPlayerWrap = styled.div`
  animation: fadein 0.5s;
  background-color: white;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  border: 12px solid black;
  border-radius: 4px;
  display: ${(props) => (props.playVideo ? `` : 'none')};
  height: 34rem;
  left: 50%;
  position: fixed;
  top: 10%;
  transform: translateX(-50%);
  width: 45rem;

  z-index: 1000;

  ${screenLessThan('1000px')} {
    height: 25rem;
    left: 50%;

    transform: translateX(-50%);
    width: 33rem;
  }
  ${screenLessThan('650px')} {
    height: 18rem;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
    width: 89%;
  }
  ${screenLessThan('420px')} {
    height: 12rem;
    left: 50%;
    overflow: hidden;
    transform: translateX(-50%);
    width: 90%;
  }
`;

export default HomeVideo;
