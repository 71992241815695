import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../../components/Button';
import { P } from '../../components/Text';
import BouncingDot from './BouncingDot';
import { featurePoints } from './Home.helper';
import { RedirectToSSOSignUp } from '../../util';
import {
  FONT_MEDIUM,
  jumpYAxis,
  pulse,
  screenLessThan,
} from '../../shared/styles';
import RedCircle from '../../../static/svgs/home/redCircle.svg';
import StarBiz from '../../../static/svgs/home/star-biz.svg';

const AllInOneOperations = () => {
  const { t } = useTranslation();

  const [checkboxFeatures, setCheckboxFeatures] = useState(featurePoints);
  const [buttonShake, setButtonShake] = useState(false);
  const [circleColor, setCircleColor] = useState();

  useEffect(() => {
    if (buttonShake) {
      const timeout = setTimeout(() => {
        setButtonShake(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [buttonShake]);

  const checkboxHandler = (id) => {
    let isUpdated = { isChecked: false, bg: '' };
    const updatedCheckboxFeatures = checkboxFeatures.map((ftr) => {
      const flag = ftr.id === id;
      isUpdated = flag ? { isChecked: !ftr.isCheck, bg: ftr.bg } : isUpdated;
      return flag ? { ...ftr, isCheck: !ftr.isCheck } : ftr;
    });
    setCheckboxFeatures(updatedCheckboxFeatures);
    setButtonShake(isUpdated.isChecked);
    setCircleColor(isUpdated.bg);
  };

  return (
    <FeatureContainer>
      <BackgroundImage src={RedCircle} />
      <LabelsWrapper>
        {checkboxFeatures.map((point, key) => (
          <FeatureLabel key={key} onClick={() => checkboxHandler(point.id)}>
            <BouncingDot>{point.isCheck && <CheckIconBall />}</BouncingDot>
            <img src={point.img} alt="all-in-one-icons" />
            <span>{t(point.transProp)}</span>
          </FeatureLabel>
        ))}
      </LabelsWrapper>
      <div className="mt-2 mb-2">
        {buttonShake && <AnimatedCircle bg={circleColor} />}
        <AnimatedButton onClick={RedirectToSSOSignUp} buttonShake={buttonShake}>
          {t('home.free_trail')}
        </AnimatedButton>
      </div>
      <SubTextDiv>
        <SubText>
          <img src={StarBiz} alt="left-star" /> &nbsp;
          {t('home.hero-cc')}
        </SubText>
        <SubText>
          <img src={StarBiz} alt="right-star" />
          &nbsp;
          {t('home.biz_is_free')}
        </SubText>
      </SubTextDiv>
    </FeatureContainer>
  );
};

export default AllInOneOperations;

const SubTextDiv = styled.div`
  display: flex;
  ${screenLessThan('700px')} {
    display: block;
  }
`;

const BackgroundImage = styled.img`
  filter: blur(110px);
  left: 40%;
  pointer-events: none;
  position: absolute;
  top: 10%;
  width: 17%;
  z-index: -10;
  ${screenLessThan('700px')} {
    filter: blur(70px);
    width: 35%;
  }
`;

const SubText = styled(P)`
  align-items: center;
  color: #1d2d5b;
  display: flex;
  padding: 0rem 1rem;
  ${FONT_MEDIUM}
`;

const FeatureContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 0;
  ${screenLessThan('500px')} {
    margin-top: 1rem;
  }
`;
const AnimatedButton = styled(Button)`
  font-size: 18px;
  height: 3rem;
  padding: 1.6rem 3rem;
  ${(props) =>
    props.buttonShake &&
    css`
      animation: ${pulse} 1s ease-in-out both;
    `};
`;

const AnimatedCircle = styled.span`
  animation: ${jumpYAxis} 3s both;
  background-color: #fee5e0;
  border-radius: 50%;
  display: inline-block;
  left: 50%;
  min-height: 18px;
  min-width: 18px;
  position: absolute;
  z-index: -1;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  ${screenLessThan('900px')} {
    flex-wrap: nowrap;
    justify-content: flex-start;
    overflow-x: scroll;
    width: 100%;
  }
`;

const FeatureLabel = styled.div`
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: center;
  margin: 0.5rem;
  margin-left: 1rem;
  position: relative;
  width: 170px;
  ${FONT_MEDIUM};

  &:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

  > span {
    margin-top: 15px;
    text-align: center;
  }
  > img {
    margin-left: auto;
    margin-right: auto;
    ${screenLessThan('900px')} {
      height: auto;
      min-width: auto;
    }
  }

  > div {
    margin-left: ${({ language }) =>
      language?.dir === 'rtl' ? '10px' : '1px'};
  }

  > div > span > .MuiSvgIcon-root {
    height: 15px;
    width: 100%;
    z-index: 10;
  }
  ${screenLessThan('900px')} {
    height: 120px;
    min-width: 150px;
  }
`;

const CheckIconBall = styled(CheckIcon)`
  color: #fa8c84;
  width: 12px;
`;
