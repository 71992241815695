import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BouncingDot = ({ children }) => <DotWrapper>{children}</DotWrapper>;

const DotWrapper = styled.div`
  align-items: center;
  background-color: #fdeff1;
  border: 1px solid #fee5e0;
  border-radius: 50%;
  display: flex;
  height: 18px;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 18px;
`;

BouncingDot.propTypes = {
  children: PropTypes.node,
};

export default BouncingDot;
