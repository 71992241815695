import React, { useEffect } from 'react';

const embedId = 'n9w70m0n46';

const insertScriptHead = ({ name, src }) => {
  if (!document.querySelector(`#${name}`)) {
    const container = document.head || document.querySelector('head');
    const scriptElement = document.createElement('script');
    scriptElement.setAttribute('id', name);
    scriptElement.async = true;
    scriptElement.src = src;
    container.appendChild(scriptElement);
  }
};

const wistiaScriptsHandler = () => {
  const requiredScripts = [
    {
      name: 'wistia-script',
      src: 'https://fast.wistia.com/assets/external/E-v1.js',
    },
    {
      name: 'wistia-script',
      src: `https://fast.wistia.com/embed/medias/${embedId}.jsonp`,
    },
  ];
  requiredScripts.forEach((v) =>
    insertScriptHead({
      name: v.name,
      src: v.src,
    })
  );
};

/**
 * Wistia Player
 */
const WisitaVideo = () => {
  useEffect(() => {
    wistiaScriptsHandler(embedId);
  }, []);

  return (
    <div
      className={`wistia_embed wistia_async_${embedId}`}
      style={{ position: 'relative', width: '100%', height: '100%' }}
    >
      &nbsp;
    </div>
  );
};

export default WisitaVideo;
