import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link } from 'gatsby';
import { Button } from '../../components/Button';
import TabPanel from '../../components/TabPanel';
import { H2 } from '../../components/Text';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ROUTES } from '../../shared/routes';

import { bizFeaturesTab } from '../../modules/home/Home.helper';
import {
  FONT_BOLD,
  FONT_MEDIUM,
  PRIMARY_COLOR,
  screenLessThan,
  SPACE_MD,
} from '../../shared/styles';
import FadeInAnimation from '../../shared/styles/FadeInAnimation';
import RedCircle from '../../../static/svgs/home/redCircle.svg';

const FeatureList = ({ page }) => {
  const { t, i18n } = useTranslation();
  const screenSize = useWindowDimensions().width;
  const showOrientation = useMemo(
    () => (screenSize < 900 ? 'vertical' : 'horizontal'),
    [screenSize]
  );
  const [value, setValue] = useState(0);
  const featureCardsCount = screenSize < '900' ? 6 : 9;

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <div data-aos={FadeInAnimation()}>
      <Heading>{t('home.feature.title')}</Heading>

      <BizDetailsWrap>
        <RedCircleBackGround src={RedCircle} alt="top-circle" />
        <RedCircleBackGround2 src={RedCircle} alt="bottom-circle" />
        <HomeFeaturesListTabs
          orientation={showOrientation}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons={false}
          aria-label=""
        >
          {bizFeaturesTab.map((eachTab, i) => (
            <HomeFeaturesListTab
              dir={i18n.dir()}
              key={i}
              label={t(eachTab.title)}
            />
          ))}
        </HomeFeaturesListTabs>
      </BizDetailsWrap>

      <LabelsWrapper>
        {bizFeaturesTab.map((eachTab, index) => {
          return (
            <FeatureTabPanel key={index} value={value} index={index}>
              {eachTab.featureList.map((feature, index) => {
                if (page)
                  return (
                    <HomeFeatureCard key={feature.title}>
                      <img src={feature.image} />
                      <span>{t(feature.title)}</span>
                      <p> {t(feature.description)}</p>
                    </HomeFeatureCard>
                  );
                else if (index >= featureCardsCount) return null;
                else
                  return (
                    <HomeFeatureCard key={feature.title}>
                      <img src={feature.image} />
                      <span>{t(feature.title)}</span>
                      <p> {t(feature.description)}</p>
                    </HomeFeatureCard>
                  );
              })}
            </FeatureTabPanel>
          );
        })}
        <ViewAllFeaturesButton
          $page={page}
          as={Link}
          to={ROUTES.featuresFullView}
        >
          {t('home.feature.button')}
        </ViewAllFeaturesButton>
      </LabelsWrapper>
    </div>
  );
};

const Heading = styled(H2)`
  margin-top: 3rem;
  ${FONT_BOLD}
`;
const RedCircleBackGround2 = styled.img`
  filter: blur(1150px);
  left: 30%;
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%);
  width: 30%;
  z-index: -1;
  ${screenLessThan('700px')} {
    filter: blur(70px);
    width: 35%;
  }
`;

const RedCircleBackGround = styled.img`
  filter: blur(140px);
  left: 30%;
  position: absolute;
  top: 30%;
  transform: translate(-50%, -50%);
  width: 20%;
  z-index: -1;
  ${screenLessThan('700px')} {
    filter: blur(70px);
    width: 35%;
  }
`;

const ViewAllFeaturesButton = styled(Button)`
  display: ${(props) => (props.$page ? `none` : '')};
  margin: 0 auto;
  padding: 0 2rem;
  text-decoration: none;
`;
const FeatureTabPanel = styled(TabPanel)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 46px;
  padding-bottom: 60px;
  ${screenLessThan('900px')} {
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: scroll;
    padding: 0;
    width: 100%;
  }
`;

const HomeFeatureCard = styled.div`
  background-color: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 317px;
  margin: auto 1.5rem 2rem;
  position: relative;
  text-align: center;
  width: 350px;
  ${screenLessThan('900px')} {
    height: auto;
    width: 300px;
  }

  ${FONT_MEDIUM};

  &:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }

  > span {
    font-size: 20px;
    font-weight: bold;
    padding: 0rem 1.5rem;
  }
  > p {
    padding: 1rem 1.5rem;
  }
  > img {
    margin: 3rem auto 2rem;
    width: 60px;
    height: 60px;
  }

  > div {
    margin-left: ${({ language }) =>
      language?.dir === 'rtl' ? '10px' : '1px'};
  }
`;

const BizDetailsWrap = styled.div`
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-left: auto;
  ${screenLessThan('900px')} {
    width: 100%;
  }
`;

const HomeFeaturesListTabs = styled(Tabs)`
  background-color: white;
  border: 1px solid #e7e7e7;
  border-radius: 8px;

  margin-top: 2rem;
  padding: 10px;
  position: relative;
  width: 79%;
  .MuiTabs-indicator {
    display: none;
  }

  ${screenLessThan('1000px')} {
    padding-left: ${`calc(${SPACE_MD} - 20px)`};
    padding-right: ${`calc(${SPACE_MD} - 20px)`};
  }
  ${screenLessThan('825px')} {
    margin-top: 0rem;
    padding-left: ${`calc(${SPACE_MD} - 0px)`};
    padding-right: ${`calc(${SPACE_MD} - 0px)`};
    width: 68%;
  }
`;

const HomeFeaturesListTab = styled(Tab)`
  border-radius: 8px;
  color: black;
  font-size: 18px !important;
  font-weight: 500 !important;
  justify-content: center;
  margin-left: auto;
  margin-left: auto;
  ${FONT_BOLD}
  min-width: unset;
  padding: ${(props) =>
    props?.dir === 'rtl' ? '0rem 4rem' : '0rem 2.98rem'} !important;
  text-transform: unset !important;
  &.Mui-selected {
    background-color: ${PRIMARY_COLOR};
    color: white !important;
  }
  ${screenLessThan('900px')} {
    margin-left: auto;
    margin-right: auto;
    padding: 0rem 2rem 0 2rem !important;
    .MuiTab-root {
      margin: 0 10px;
    }
  }
`;

FeatureList.propTypes = {
  page: PropTypes.bool,
};

export default FeatureList;
