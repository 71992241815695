import accountingMadeEasy from '../../../static/svgs/AccordionSection/accounting.svg';
import chartsOfAccounts from '../../../static/svgs/AccordionSection/charts-of-accounts.svg';
import clientManagement from '../../../static/svgs/AccordionSection/client-mangagement.svg';
import dashboard from '../../../static/svgs/AccordionSection/dashboard.svg';
import eSignature from '../../../static/svgs/AccordionSection/e-signatures.svg';
import gstTax from '../../../static/svgs/AccordionSection/gst-tax.svg';
import modern from '../../../static/svgs/AccordionSection/modern.png';
import poductCatalougue from '../../../static/svgs/AccordionSection/product-catalogues.svg';
import purchaseOrder from '../../../static/svgs/AccordionSection/purchase-order.svg';
import reports from '../../../static/svgs/AccordionSection/reports.svg';
import security from '../../../static/svgs/AccordionSection/security.svg';
import setup from '../../../static/svgs/AccordionSection/setup-payment.svg';
import smartNotification from '../../../static/svgs/AccordionSection/smart-notifications.svg';
import style from '../../../static/svgs/AccordionSection/style-customizations.svg';
import taskManagement from '../../../static/svgs/AccordionSection/task-managemnt.svg';

const invoiceTabs = [
  {
    title: 'home.invoices.accordion.style.title',
    content: 'home.invoices.accordion.style.content',
    image: style,
  },
  {
    title: 'home.invoices.accordion.modern.title',
    content: 'home.invoices.accordion.modern.content',
    image: modern,
  },

  {
    title: 'home.invoices.accordion.paid.title',
    content: 'home.invoices.accordion.paid.content',
    image: setup,
  },
  {
    title: 'home.invoices.accordion.signatures.title',
    content: 'home.invoices.accordion.signatures.content',
    image: eSignature,
  },
  {
    title: 'home.invoices.accordion.gst.title',
    content: 'home.invoices.accordion.gst.content',
    image: gstTax,
  },
];

const clientsTab = [
  {
    title: 'home.clients.accordion.client.title',
    content: 'home.clients.accordion.client.content',
    image: clientManagement,
  },
  {
    title: 'home.clients.accordion.smart.title',
    content: 'home.clients.accordion.smart.content',
    image: smartNotification,
  },
  {
    title: 'home.clients.accordion.product.title',
    content: 'home.clients.accordion.product.content',
    image: poductCatalougue,
  },
  {
    title: 'home.clients.accordion.purchase.title',
    content: 'home.clients.accordion.purchase.content',
    image: purchaseOrder,
  },
  {
    title: 'home.clients.accordion.task.title',
    content: 'home.clients.accordion.task.content',
    image: taskManagement,
  },
];

const reportsTab = [
  {
    title: 'home.reports.accordion.dashboard.title',
    content: 'home.reports.accordion.dashboard.content',
    image: dashboard,
  },
  {
    title: 'home.reports.accordion.security.title',
    content: 'home.reports.accordion.security.content',
    image: security,
  },
  {
    title: 'home.reports.accordion.accounting.title',
    content: 'home.reports.accordion.accounting.content',
    image: accountingMadeEasy,
  },
  {
    title: 'home.reports.accordion.reports.title',
    content: 'home.reports.accordion.reports.content',
    image: reports,
  },
  {
    title: 'home.reports.accordion.charts.title',
    content: 'home.reports.accordion.charts.content',
    image: chartsOfAccounts,
  },
];

export const AccordionTabs = [
  {
    title: 'home.invoices.title',
    subtitle: 'home.invoices.subtitle',
    content: invoiceTabs,
  },
  {
    title: 'home.clients.title',
    subtitle: 'home.clients.subtitle',
    content: clientsTab,
  },
  {
    title: 'home.reports.title',
    subtitle: 'home.reports.subtitle',
    content: reportsTab,
  },
];
