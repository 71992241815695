/* eslint-disable import/no-unused-modules */
import React from 'react';
import { graphql } from 'gatsby';
import BizFeaturesMarquee from '../components/BizFeaturesMarquee';
import FeatureList from '../components/FeatureList/FeatureList';
import Helmet from '../components/Helmet';
import HomeImageList from '../components/List/HomeImageList';
import ProfessionalInvoicesSection from '../modules/home-accordion/HomeAccordionDisplay';
import AllInOneOperations from '../modules/home/AllInOneOperations';
import HeroSection from '../modules/home/HeroSection';
import Customers from '../modules/home/HomeCustomers';
import HomeVideo from '../modules/home/HomeVideo';
import PeekToAppWindow from '../modules/home/PeekToAppWindow';
import TrialStart from '../modules/home/TrialStart';
import withI18n from '../hocs/withI18n';
import { CLIENT_LIST, PARTNERS_AND_CERTIFICATIONS_LIST } from '../config';
export const Head = withI18n(() => {
  return <Helmet pageName="home" />;
});

const Home = () => {
  return (
    <>
      <HeroSection />
      <AllInOneOperations />
      <PeekToAppWindow />
      <HomeImageList title="home.loved_by_teams" ImageList={CLIENT_LIST} />
      <HomeVideo />
      <ProfessionalInvoicesSection />
      <FeatureList page={false} />
      <Customers />
      <BizFeaturesMarquee />
      <TrialStart />
      <HomeImageList
        title="home.partner.title"
        ImageList={PARTNERS_AND_CERTIFICATIONS_LIST}
      />
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default Home;
