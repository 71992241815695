import React from 'react';
import styled from 'styled-components';

import useWindowDimensions from '../../hooks/useWindowDimensions';
import { screenLessThan, SCREEN_PAD } from '../../shared/styles';
import webwindowMobile from '../../../static/images/webwindow-mobile.png';
import blueCircle from '../../../static/svgs/home/blueCircle.svg';
import RedCircle from '../../../static/svgs/home/redCircle.svg';
import webwindow from '../../../static/svgs/home/webwindow.svg';
import yellowCircle from '../../../static/svgs/home/yellowCircle.svg';

const PeekToAppWindow = () => {
  const screenSize = useWindowDimensions().width;
  return (
    <WebwindowWrap>
      <YellowCircleBackground src={yellowCircle} alt="yellow-circle" />
      <RedCircleBackGround src={RedCircle} alt="red-circle" />
      <BlueCircleBackGround src={blueCircle} alt="blue-circle" />
      <BizWindow
        src={screenSize < 500 ? webwindowMobile : webwindow}
        alt="Biz screenshot"
      />
    </WebwindowWrap>
  );
};

const BizWindow = styled.img`
  height: 100%;
  max-width: 100%;
  z-index: 10;
`;

const BlueCircleBackGround = styled.img`
  filter: blur(80px);
  left: 20%;
  pointer-events: none;
  position: absolute;
  top: 150%;
  transform: translate(-50%, -50%);
  width: 25%;
  ${screenLessThan('700px')} {
    filter: blur(70px);
    width: 35%;
  }
`;

const RedCircleBackGround = styled.img`
  filter: blur(70px);
  left: 55%;
  pointer-events: none;
  position: absolute;
  top: 70%;
  transform: translate(-50%, -50%);
  width: 20%;
  ${screenLessThan('700px')} {
    filter: blur(70px);
    width: 35%;
  }
`;

const YellowCircleBackground = styled.img`
  filter: blur(70px);
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 13%;
  ${screenLessThan('700px')} {
    filter: blur(70px);
    width: 35%;
  }
`;

const WebwindowWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 4%;
  padding-top: 4%;
  position: relative;
  ${SCREEN_PAD}
  > img {
    height: 100%;
    max-width: 100%;
  }
  ${screenLessThan('500px')} {
    padding-bottom: 2rem;
  }
`;

export default PeekToAppWindow;
