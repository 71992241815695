import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { H2, P } from '../../components/Text';
import AccordionTextLayout from './HomeAccordionsList.js';
import { isOdd } from '../../util';
import { FONT_MEDIUM, screenLessThan } from '../../shared/styles';

const AccordionBody = ({
  index,
  opened,
  content,
  setOpened,
  title,
  subtitle,
}) => {
  const openedAccordion = content[opened];
  return (
    <div>
      <AccordionTitleWrapper>
        <H2 className="title">{title}</H2>
        <P className="subtitle">{subtitle}</P>
      </AccordionTitleWrapper>
      <SectionWrapper direction={isOdd(index) ? '' : 'reverse'}>
        <SectionPortionWrapper isImagePortion={isOdd(index)}>
          <SelectedImage src={openedAccordion.image} />
        </SectionPortionWrapper>
        <SectionPortionWrapper>
          <AccordionTextLayout content={content} setOpened={setOpened} />
        </SectionPortionWrapper>
      </SectionWrapper>
    </div>
  );
};

const AccordionTitleWrapper = styled.div`
  flex: 50%;
  flex-wrap: wrap;
  padding: 2rem 10% 0 10%;
  position: relative;
  text-align: center;
  ${screenLessThan('1290px')} {
    padding-left: 00%;
    padding-right: 0rem;
  }
  ${screenLessThan('1000px')} {
    padding-left: 0;
  }
  .subtitle {
    margin: auto;
    margin-bottom: 2rem;
    text-align: center;
    width: 45%;
    line-height: 24px;
    font-size: 16px;

    ${screenLessThan('770px')} {
      font-size: 17px;
      margin-bottom: 1.2rem;
      text-align: center;
      width: 100%;
    }
  }

  .title {
    font-weight: bold;
    margin: auto;
    margin-bottom: 1.8rem;
    margin-top: 1.6rem;
    text-align: center;
    width: 75%;

    ${screenLessThan('770px')} {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
`;

const SelectedImage = styled.img`
  display: block;
  height: auto;
  margin: auto;
  width: 600px;

  ${screenLessThan('1100px')} {
    width: 400px;
  }
  ${screenLessThan('770px')} {
    width: 90%;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  padding-bottom: 2rem;

  ${(props) =>
    props.direction === 'reverse' &&
    css`
      flex-direction: row-reverse;
    `}
  ${screenLessThan('1000px')} {
    flex-direction: column-reverse;
    margin-bottom: 3rem;
    padding-top: 0rem;
  }
`;
const SectionPortionWrapper = styled.div`
  display: flex;
  flex: ${(props) => (props.isImagePortion ? '50%' : '50%')};
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  h2 {
    ${FONT_MEDIUM};
    letter-spacing: 1px;
  }
  p {
    ${FONT_MEDIUM};
    line-height: 2;
  }
  ${screenLessThan('1000px')} {
    padding: 0;
    h2,
    p {
      text-align: center;
    }
  }
`;

AccordionBody.propTypes = {
  index: PropTypes.number,
  opened: PropTypes.number,
  content: PropTypes.array,
  setOpened: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default AccordionBody;
