import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AccordionBody from './HomeAccordions';
import { AccordionTabs } from './HomeAccordion.helper';
import { screenLessThan } from '../../shared/styles';
import FadeInAnimation from '../../shared/styles/FadeInAnimation';

const HomeAccordionDisplay = () => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(0);
  return (
    <InvoiceElements data-aos={FadeInAnimation()}>
      <div style={{ width: '100%' }}>
        {AccordionTabs.map((item, index) => (
          <AccordionBody
            key={index}
            opened={opened}
            setOpened={setOpened}
            index={index}
            title={t(item.title)}
            subtitle={t(item.subtitle)}
            content={item.content}
          />
        ))}
      </div>
    </InvoiceElements>
  );
};

const InvoiceElements = styled.div`
  background: #f8f8f8;
  padding-bottom: 70px;
  padding-left: 5rem;
  padding-right: 5rem;

  ${screenLessThan('1290px')} {
    height: auto;
    padding-bottom: 90px;
    padding-right: 1.5rem;
  }
  ${screenLessThan('800px')} {
    height: auto;
    padding-bottom: 1px;
    padding-left: 1.5rem;
    padding-top: 10px;
  }
`;

export default HomeAccordionDisplay;
