import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../../components/Accordion';
import { PMedium } from '../../components/Text';
import { screenLessThan } from '../../shared/styles';

const AccordionTextLayout = ({ content, setOpened }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(null);
  useEffect(() => {
    setOpened(0);
    setExpanded(0);
  }, [setOpened]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setOpened(panel);
  };
  return (
    <>
      {content.map((item, i) => (
        <AccordionDiv
          key={i}
          square
          expanded={expanded === i}
          onChange={handleChange(i)}
        >
          <AccordionTitle expandIcon={<ExpandMoreIcon />}>
            <Subtext>{t(item.title)}</Subtext>
          </AccordionTitle>
          <InvoiceAccordionDetails>
            <span>{t(item.content)}</span>
          </InvoiceAccordionDetails>
        </AccordionDiv>
      ))}
    </>
  );
};
const Subtext = styled(PMedium)`
  font-weight: 600 !important;
  margin-bottom: 0px;
  margin-top: 16px;
  text-align: left !important;
  ${screenLessThan('1140px')} {
    font-size: 16px;
  }
`;

const AccordionDiv = styled(Accordion)`
  background-color: transparent;
  border-bottom: 0px;
  width: 80%;
  ${screenLessThan('1140px')} {
    width: 100%;
  }
  &.Mui-expanded {
    background-color: #f3f5f5;
    border: 1px solid #e8ecee;
    border-radius: 13px;
  }
`;
const AccordionTitle = styled(AccordionSummary)`
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  padding: 0rem 3.3rem 0rem 3.3rem !important;

  ${screenLessThan('770px')} {
    padding: 0rem 1.5rem 0 2.5rem !important;
    & .MuiAccordionSummary-content {
      font-size: 16px;
    }
  }
`;

const InvoiceAccordionDetails = styled(AccordionDetails)`
  background-color: transparent;
  font-weight: 400;
  padding: 0rem 3.3rem 1.3rem !important;
  ${screenLessThan('770px')} {
    font-size: 16px;
    padding: 0 1rem 1rem 2.5rem !important;
  }
`;

AccordionTextLayout.propTypes = {
  content: PropTypes.array,
  setOpened: PropTypes.func,
};

export default AccordionTextLayout;
